import React, { useState } from "react";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import Radio from "../../components/RadioButtons/RadioButton";
import styles from "./index.module.scss";
import {  doc, updateDoc } from "firebase/firestore";
import { tetherxFirestore } from "../../firebase";
import ButtonRade from "../../components/RadeButtons";

const TicketStatusSwitchPopUp = ({ setModalIsOpen, ticketID,selectedTicketEmail }) => {
  const [statusSwitchInput, setStatusSwitchInput] = useState(""); //state for storing the status input

  // Function to handle the status switching of the transfer
  const handleClickStatusSwitch = (e) => {
    setStatusSwitchInput(e.target.value);
  };

  // Function to handle cancel button
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };

  const handleClickUpdateButton = async () => {
    try {
      
      const ticketRef = doc(
        tetherxFirestore,
        "ticketCollection",
        selectedTicketEmail,
        "ticketList",
        ticketID
      );

      await updateDoc(ticketRef, { ticketStatus: statusSwitchInput });
      console.log("Ticket status updated successfully!");

      setModalIsOpen(false);
    } catch (error) {
      console.error("Error updating ticket status: ", error);
    }};

  // Radio button options
  const statusSwitchOptions = [
    { value: "Resolved", label: "Resolved" },
    { value: "Open", label: "Open" },
  ];

  return (
    <div>
      <div className={styles.changeStatus}>{PZ_TRANSFER_ENUM.changeStatus}</div>
      <div className={styles.radioButtonContainer}>
        <Radio
          name="statusSwitchOptions"
          value={statusSwitchInput}
          options={statusSwitchOptions}
          onChangeFunc={handleClickStatusSwitch}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonRade
          customStyling={styles.cancelButton}
          onClick={handleClickCancelButton}
        >
          {PZ_TRANSFER_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          customStyling={styles.updateButton}
          onClick={handleClickUpdateButton}
        >
          {PZ_TRANSFER_ENUM.updateButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default TicketStatusSwitchPopUp;
