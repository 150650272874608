import React, { useEffect, useState, useMemo } from "react";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { PZ_ST_ENUM } from "../../enums/PZSTEnum";
import styles from "./index.module.scss";

const PZST = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const getData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch("http://localhost:3030/posts", requestOptions)
      .then((response) => response.json())
      .then((result) => setData(result))
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    getData();
  }, []);

  const filteredData = useMemo(() => {
    return data.filter((post) =>
      post.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  const totalPages = Math.ceil(filteredData.length / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);

  return (
    <div>
      <div className={styles.transferOrders}>
        <div className={styles.refreshButtonContainer}>
          <div className={styles.searchBarDiv}>
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearch}
            />
            <img
              src={searchIcon}
              className={styles.searchIcon}
              alt="searchIcon"
            />
          </div>
          <ButtonRade customStyling={styles.refreshButton} onClick={getData}>
            <img
              src={refreshIcon}
              alt="refreshIcon"
              className={styles.refreshIcon}
            />
          </ButtonRade>
        </div>
        <div className={styles.searchContainer}>
          <div className={styles.search}>
            Show
            <input
              type="number"
              className={styles.searchPage}
              min={1}
              value={pageSize}
              onChange={(e) => setPageSize(parseInt(e.target.value))}
            />
          </div>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredData.length}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>{PZ_ST_ENUM.serviceTicket}</th>
            <th>{PZ_ST_ENUM.email}</th>
            <th>{PZ_ST_ENUM.phone}</th>
            <th>{PZ_ST_ENUM.type} </th>
            <th>{PZ_ST_ENUM.attachment}</th>
            <th>{PZ_ST_ENUM.message}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((post) => (
            <tr key={post.id}>
              <td>{post.serviceTicket}</td>
              <td>{post.email}</td>
              <td>{post.phone}</td>
              <td>{post.type}</td>
              <td>{post.attachment}</td>
              <td>{post.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default PZST;
