import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { payezyFirestore, radexAuth } from "../../firebase";
import styles from "./index.module.scss";
import { FULFILLMENT_ENUM } from "../../enums/fulfillmentEnum";
import SpinnerButton from "../../components/SpinnerButton/SpinnerButton";
function PZFulfillment() {
  const [messages, setMessages] = useState([]); //state to store messages
  const [selectedMessages, setSelectedMessages] = useState([]); //state to store selected messages using check box
  const [isFetching, setIsFetching] = useState(false); //state to track API call
  const [accountDetails, setAccountDetails] = useState(null); //state to store account details of the receiver
  const [paymentReferenceError, setPaymentReferenceError] = useState(""); //state to store validation error for payment reference
  const [paymentRefs, setPaymentRefs] = useState([]); //state to store payment ref
  const [processButtonTimeout, setProcessButtonTimeout] = useState(null); //state to track the process button timeout
  const [fetchButtonTimeout, setFetchButtonTimeout] = useState(null); //state to track the fetch button timeout
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  //state to track whether the process button should be disabled
  const [isProcessButtonDisabled, setIsProcessButtonDisabled] = useState(true);
  // state to track whether the fetch button should be disabled
  const [isFetchButtonDisabled, setIsFetchButtonDisabled] = useState(false);
  const MAX_DECIMAL_PLACE = 2; //variable that store maximum decimal place after a digit
  // Classname for the proceesSelectedMessage button
  const processSelectedbuttonClass =
    selectedMessages.length && !isProcessButtonDisabled
      ? styles.processSelectedMessageButton // When the button is enabled
      : styles.processSelectedMessageButtonDisabled; // When the button is disabled
  const processFetchbuttonClass = !isFetchButtonDisabled
    ? styles.processFetchButton // When the button is enabled
    : styles.processFetchButtonDisabled; // When the button is disabled
  //Resetting the timer
  //Resetting the timer
  const resetProcessButtonTimer = () => {
    setIsProcessButtonDisabled(true);

    const processButtonTimer = setTimeout(() => {
      setIsProcessButtonDisabled(false);
    }, 30000);

    setProcessButtonTimeout(processButtonTimer);
  };
  // Fetch account details from the firebase database using senderEmail and recieverAccountId (Uniquely generated id in the firebase)
  const fetchAccountDetails = async (senderEmail, receiverAccountId) => {
    try {
      // Construct the reference to the accountDetails document for the receiverAccountId
      const accountDetailsDocRef = doc(
        payezyFirestore,
        `userData/${senderEmail}/accountDetails/${receiverAccountId}`
      );

      // Get the account details using the document reference
      const accountDetailSnapshot = await getDoc(accountDetailsDocRef);

      if (!accountDetailSnapshot.exists()) {
        console.log(
          "No account details found for receiverAccountID:",
          receiverAccountId
        );
        return null;
      }

      const accountDetail = accountDetailSnapshot.data();

      return accountDetail;
    } catch (error) {
      console.error("Error fetching account details:", error);
      return null;
    }
  };

  // Fetch account details for each message's receiver_account_id
  useEffect(() => {
    if (messages.length > 0) {
      const fetchAccountDetailsForMessages = async () => {
        const accountDetailsArray = [];
        for (const message of messages) {
          const messageData = JSON.parse(message.Body);
          const messageBody = JSON.parse(messageData.Message);
          const receiverAccountId = messageBody.receiver_account_id;
          const senderEmail = messageBody.sender_email;
          // If there is no matching reciever_account_id
          if (!receiverAccountId) {
            setErrorMessage("Receiver account ID missing in message!");
            console.warn("Receiver account ID missing in message:", message);
            continue;
          }

          const accountDetail = await fetchAccountDetails(
            senderEmail,
            receiverAccountId
          );
          if (accountDetail) {
            accountDetailsArray.push({
              ...accountDetail,
              receiverAccountId,
            });
          }
        }
        setAccountDetails(accountDetailsArray);
      };

      fetchAccountDetailsForMessages();
    }
  }, [messages]);

  // Fetch messaged from the queue
  const fetchMessages = async () => {
    setErrorMessage("");
    if (processButtonTimeout) {
      clearTimeout(processButtonTimeout);
    }

    if (fetchButtonTimeout) {
      clearTimeout(fetchButtonTimeout);
    }

    resetProcessButtonTimer();

    try {
      setIsFetching(true);

      setTimeout(() => {
        setIsProcessButtonDisabled(true); // Disable the process button after 10 seconds
      }, 30000);
      setIsFetchButtonDisabled(true); // Disable the fetch button
      setTimeout(() => {
        setIsFetchButtonDisabled(false); // Enable the fetch button after 5 seconds
      }, 5000);
      // Getting order queue from server API
      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
        },
      };
      const response = await fetch(
        process.env.REACT_APP_PAYEZY_SERVER_URI +
          "/admin-fetch-inr-orders-queue",
        requestOptions
      );
      if (!response.ok) {
        setErrorMessage("Failed to fetch messages from inr order queue!");
        throw new Error("Failed to fetch messages from inr order queue");
      }

      const data = await response.json();

      // Make sure data is an array before updating state, if not will have problems in mapping during render
      if (Array.isArray(data)) {
        setMessages(data);
      } else {
        setErrorMessage("Invalid response format!");
        console.error("Invalid response format:", data);
      }
      const newFetchButtonTimer = setTimeout(() => {
        setIsFetchButtonDisabled(false);
      }, 5000);

      setFetchButtonTimeout(newFetchButtonTimer);
      setIsProcessButtonDisabled(false); // Enable the process button
      setIsFetching(false);
    } catch (error) {
      setErrorMessage("Error fetching messages:", error.message);
      console.error("Error fetching messages:", error);
      setIsFetching(false);
    }
  };

  // Whenever a checkbox is selected or deselected, change the state variable
  // this is needed to pass as argument for process requests
  const handleCheckboxChange = (event, messageId) => {
    setErrorMessage("");
    if (event.target.checked) {
      setSelectedMessages((prevSelected) => [...prevSelected, messageId]);
    } else {
      setSelectedMessages((prevSelected) =>
        prevSelected.filter((id) => id !== messageId)
      );
    }
  };
  // Function for processing selected messages in the queue
  // Note that there is a standard expiry of 1 hour (visibility timeout) for the rcptHndle keys (or messages in general).
  // After that, the user needs to refresh to get the new rcptHndle keys to send process request
  const processSelectedMessages = async () => {
    setErrorMessage("");
    setIsFetching(true);
    try {
      // Check if any payment reference field is empty
      for (const message of messages) {
        const isChecked = selectedMessages.includes(message.MessageId);
        if (isChecked) {
          const paymentReference = paymentRefs[message.MessageId];
          if (!paymentReference) {
            setPaymentReferenceError(
              "Please enter payment reference for all selected messages."
            );
            return;
          }
        }
      }

      // Properly declared and defined messageArray variable
      const messageArray = messages
        .filter((message) => selectedMessages.includes(message.MessageId))
        .map((message) => {
          const messageBody = JSON.parse(message.Body);
          const messageData = JSON.parse(messageBody.Message);

          return {
            receiptHandle: message.ReceiptHandle,
            userEmail: messageData.sender_email,
            txId: messageData.transaction_id,
            inrPaymentRef: paymentRefs[message.MessageId] || "",
            inr_amount_received: messageData.received_inr_amount,
          };
        });
      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );

      const response = await fetch(
        process.env.REACT_APP_PAYEZY_SERVER_URI +
          "/admin-process-inr-orders-queue",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          },
          body: JSON.stringify({ messageArray: messageArray }),
        }
      );
      if (response.ok) {
        setSelectedMessages([]); // Clear the selectedMessages array

        await fetchMessages();

        setMessages((prevMessages) =>
          prevMessages.filter(
            (message) =>
              !messageArray.some(
                (msg) => msg.receiptHandle === message.ReceiptHandle
              )
          )
        );
        // Refresh messages after successful processing and deletion
        setTimeout(function () {
          setIsFetching(false);
        }, 10000);
      }
    } catch (error) {
      console.error("Error processing messages:", error);
    }
  };

  // Whenever the input value in teh text area changes, set state
  const handlePaymentRefChange = (event, messageId) => {
    const value = event.target.value;
    setPaymentReferenceError("");
    setPaymentRefs((prevValues) => ({ ...prevValues, [messageId]: value }));
  };
  return (
    <div>
      <button
        onClick={fetchMessages}
        disabled={isFetching || isFetchButtonDisabled}
        className={processFetchbuttonClass}
      >
        {FULFILLMENT_ENUM.fetchMessages}
      </button>
      <table>
        <thead>
          <tr>
            <th>{FULFILLMENT_ENUM.select}</th>
            <th>{FULFILLMENT_ENUM.email}</th>
            <th>{FULFILLMENT_ENUM.recievedUSDAmount}</th>
            <th>{FULFILLMENT_ENUM.recievedINRAmount}</th>
            <th>{FULFILLMENT_ENUM.recieverName}</th>
            <th>{FULFILLMENT_ENUM.IFSC}</th>
            <th>{FULFILLMENT_ENUM.accountNum}</th>
            <th>{FULFILLMENT_ENUM.paymentReference}</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => {
            const messageData = JSON.parse(message.Body);
            const messageBody = JSON.parse(messageData.Message);

            // Find the account details for the current message's receiver_account_id
            const accountDetail =
              accountDetails?.find(
                (detail) =>
                  detail.receiverAccountId === messageBody.receiver_account_id
              ) || {};

            return (
              <tr key={message.MessageId}>
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e, message.MessageId)}
                  />
                </td>
                <td>{messageBody.sender_email}</td>

                <td>
                  {(
                    messageBody.received_usd_amount.amt /
                    10 ** messageBody.received_usd_amount.decimals
                  ).toFixed(MAX_DECIMAL_PLACE)}
                </td>
                <td>
                  {(
                    messageBody.received_inr_amount.amt /
                    10 ** messageBody.received_inr_amount.decimals
                  ).toFixed(MAX_DECIMAL_PLACE)}
                </td>
                <td>{accountDetail?.fullName}</td>
                <td>{accountDetail?.IFSCCode}</td>
                <td>{accountDetail?.accountNumber}</td>
                <td>
                  {" "}
                  <input
                    className={styles.inputPaymentReference}
                    type="text"
                    placeholder="Enter the payment reference"
                    value={paymentRefs[message.MessageId] || ""}
                    onChange={(e) =>
                      handlePaymentRefChange(e, message.MessageId)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        onClick={processSelectedMessages}
        disabled={!selectedMessages.length || isProcessButtonDisabled}
        className={processSelectedbuttonClass}
      >
        {isFetching ? (
          <div className={styles.spinnerDiv}>
            <SpinnerButton />
          </div>
        ) : (
          FULFILLMENT_ENUM.processSelectedMessage
        )}
      </button>
      {paymentReferenceError && (
        <p className={styles.validationError}>{paymentReferenceError}</p>
      )}
      {isProcessButtonDisabled && !isFetching && (
        <p className={styles.sessionExpiredWarning}>
          {FULFILLMENT_ENUM.sesionExpired}
        </p>
      )}
      {errorMessage && <p className={styles.validationError}>{errorMessage}</p>}
    </div>
  );
}

export default PZFulfillment;
