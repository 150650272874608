import React from "react";
import App from "./App";
import "typeface-metrophobic";
import "typeface-roboto";
import "typeface-exo";
import "typeface-lato";
import "./index.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
