import React, { useContext, useState } from "react";
import cx from "classnames";
import { AppContext } from "../../context";
import { PROFILE_ENUM } from "../../enums/profileEnum";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";

const TXUSDT = () => {
  const { isMobile } = useContext(AppContext);
  const [exchangeRate, setExchangeRate] = useState(""); //state to store the exchange rate
  // Funcion to change the exchange rate
  const handlesubmitUpdateExchangeRate = (e) => {
    setExchangeRate(e.target.value);
  };

  const CardBody = () => (
    <div>
      <div
        className={cx(styles.TXUSDTContainer, {
          [styles.TXUSDTContainerWeb]: !isMobile,
          [styles.TXUSDTContainerMob]: isMobile,
        })}
      >
        <div
          className={cx(styles.firstFlex, {
            [styles.firstFlexMob]: isMobile,
          })}
        >
          <div
            className={cx(styles.exchangeRate, {
              [styles.exchangeRateWeb]: !isMobile,
              [styles.exchangeRateMob]: isMobile,
            })}
          >
            <p
              className={cx(styles.exchangeRateContainer, {
                [styles.exchangeRateContainerWeb]: !isMobile,
                [styles.exchangeRateContainerMob]: isMobile,
              })}
            >
              Exchange Rate
            </p>
            <input
              key="name"
              type="text"
              className={cx(styles.input, {
                [styles.inputWeb]: !isMobile,
                [styles.inputMob]: isMobile,
              })}
              placeholder="Enter USDT Price"
              value={exchangeRate}
            />
          </div>
        </div>
      </div>

      <div
        className={cx(styles.updateProfileButtonDiv, {
          [styles.updateProfileButtonDivWeb]: !isMobile,
          [styles.updateProfileButtonDivMob]: isMobile,
        })}
      >
        <ButtonRade
          outline
          customStyling={cx(styles.updateProfileButton, {
            [styles.updateProfileButtonWeb]: !isMobile,
            [styles.updateProfileButtonMob]: isMobile,
          })}
          onClick={handlesubmitUpdateExchangeRate}
        >
          {PROFILE_ENUM.update}
        </ButtonRade>
        <ButtonRade
          outline
          customStyling={cx(styles.editButton, {
            [styles.editButtonWeb]: !isMobile,
            [styles.editButtonMob]: isMobile,
          })}
        >
          {PROFILE_ENUM.edit}
        </ButtonRade>
      </div>
    </div>
  );

  return <>{CardBody()}</>;
};

export default TXUSDT;
