import React, { useState, useRef } from "react";
import SelectArrow from "../../assets/SelectArrow";
import styles from "./index.scss";
import SelectArrowDown from "../../assets/SelectArrowDown";

const SelectBox = ({
  placeHolder,
  options,
  onChange,
  setSelectedValue,
  selectedValue,
  errorDismissOnclick,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef(null);
  const menuRef = useRef(null); // Add this line to create the menuRef

  const handleInputClick = () => {
    setShowMenu(true);
    errorDismissOnclick();
    inputRef.current.focus();
  };

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShowMenu(false);
    }
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    return selectedValue.label;
  };

  const onItemClick = (option) => {
    setSelectedValue(option);
    onChange(option);
    setShowMenu(false);
  };

  const isSelected = (option) => {
    if (!selectedValue) {
      return false;
    } else {
      return selectedValue.value === option.value;
    }
  };

  const getOptions = () => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div
      className="dropdownContainer"
      ref={menuRef}
      onClick={handleOutsideClick}
    >
      <div onClick={handleInputClick} className="dropdownInput">
        <input
          type="text"
          value={getDisplay()} // Use getDisplay() to show the selected value
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder={placeHolder}
          className={styles.dropdownInputField}
          ref={inputRef}
        />
        <div>
          <div className="dropdownTool">
            {!showMenu ? <SelectArrow /> : <SelectArrowDown />}
          </div>
        </div>
      </div>
      {showMenu && (
        <div className="dropdownMenu">
          {getOptions().map((option) => (
            <div className="selectOptionsDiv" key={option.value}>
              <div
                onClick={() => {
                  onItemClick(option);
                  setSearchQuery(""); // Clear search query on selection
                }}
                className={`dropdown-item ${isSelected(option) && "selected"}`}
              >
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectBox;
