import React, { useEffect, useState } from "react";
import { collection, getDocs, doc, updateDoc, getDoc } from "firebase/firestore";
import { payezyFirestore } from "../../firebase";
import Modal from "react-modal";
import ButtonRade from "../../components/RadeButtons";
import styles from "./index.module.scss";
import TicketStatusSwitchPopUp from "../TicketStatusSwitchPopUp/TicketStatusSwitchPopUp";
import editIcon from "../../assets/editIcon.png";
import SendReplyPopUp from "../SendReplyPopUp/SendReplyPopUp";

const PZServiceTicket = () => {
  // State to store the available emails that have tickets
  const [availableProfileEmails, setAvailableProfileEmails] = useState([]);
  // State to store user ticket data information
  const [PZUserTicketData, setPZUserTicketData] = useState([]);
  // State to open and close the modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // State to store the selected ticket id
  const [selectedTicketID, setSelectedTicketID] = useState(null);
  // State to store the selected ticket email
  const [selectedTicketEmail, setSelectedTicketEmail] = useState(null);
  // State to determine the current modal page
  const [modalPageNumber, setModalPageNumber] = useState(false);
  // Function to handle changing the ticket status
  const handleClickChangeTicketStatus = (ticketID, ticketEmail) => {
    setSelectedTicketID(ticketID);
    setModalIsOpen(true);
    setModalPageNumber(0);
    setSelectedTicketEmail(ticketEmail);
  };

  // Effect to fetch the available emails that have support tickets
  useEffect(() => {
    const fetchProfileEmails = async () => {
      try {
        const ticketCollectionRef = collection(
          payezyFirestore,
          "ticketCollection"
        );

        const querySnapshot = await getDocs(ticketCollectionRef);

        const emails = querySnapshot.docs.map((doc) => doc.id);
        setAvailableProfileEmails(emails);
      } catch (error) {
        console.error("Error fetching profile emails: ", error);
      }
    };

    fetchProfileEmails();
  }, []);

  // Effect to fetch the details of active support tickets
  useEffect(() => {
    const fetchAllTickets = async () => {
      try {
        const allTickets = [];

        for (const email of availableProfileEmails) {
          const userTicketsRef = collection(
            payezyFirestore,
            "ticketCollection",
            email,
            "ticketList"
          );

          const userTicketsSnapshot = await getDocs(userTicketsRef);

          userTicketsSnapshot.forEach((ticketDoc) => {
            const ticketData = ticketDoc.data();
            allTickets.push({
              email: email,
              ticketID: ticketDoc.id,
              orderID: ticketData.orderID,
              ticketType: ticketData.ticketType,
              message: ticketData.message,
              ticketReply: ticketData.ticketReply,
              ticketStatus: ticketData.ticketStatus,
            });
          });
        }

        setPZUserTicketData(allTickets);
      } catch (error) {
        console.error("Error fetching tickets: ", error);
      }
    };

    // Fetch tickets only when there are available profile emails and modal is open
    if (availableProfileEmails.length > 0) {
      fetchAllTickets();
    }
  }, [availableProfileEmails]);

  // Function to handle changes in the reply text area
  const handleReplyChange = (ticketID, event) => {
    const updatedTickets = PZUserTicketData.map((ticket) => {
      if (ticket.ticketID === ticketID) {
        return { ...ticket, ticketReply: event.target.value };
      }
      return ticket;
    });
    setPZUserTicketData(updatedTickets);
  };

  // Function to handle opening the reply pop-up
  const handleClickReplyButton = (ticketID) => {
    setSelectedTicketID(ticketID);
    setModalIsOpen(true);
    setModalPageNumber(1);
  };

  // Function to save a reply to Firestore
  const saveReplyToFirestore = async (ticketID, reply, email) => {
    const ticketRef = doc(
      payezyFirestore,
      "ticketCollection",
      email,
      "ticketList",
      ticketID
    );

    try {
      const ticketDoc = await getDoc(ticketRef);

      if (ticketDoc.exists()) {
        await updateDoc(ticketRef, { ticketReply: reply });
        console.log("Reply saved to Firestore!");
      } else {
        console.log("Document does not exist in Firestore.");
      }
    } catch (error) {
      console.error("Error saving reply to Firestore: ", error);
    }
  };

  // Function to handle saving replies to multiple tickets
  const handleSaveReply = () => {
    PZUserTicketData.forEach((ticket) => {
      if (ticket.ticketReply) {
        saveReplyToFirestore(ticket.ticketID, ticket.ticketReply, ticket.email);
      }
    });
    setModalIsOpen(false); // Close the modal after saving the replies
  };

  // Function to render the content of the modal based on the current page
  const renderModalContent = () => {
    switch (modalPageNumber) {
      case 0:
        return (
          <TicketStatusSwitchPopUp
            setModalIsOpen={setModalIsOpen}
            ticketID={selectedTicketID}
            selectedTicketEmail={selectedTicketEmail}
          />
        );
      case 1:
        return (
          <SendReplyPopUp
            setModalIsOpen={setModalIsOpen}
            selectedTicketID={selectedTicketID}
            handleReplyChange={handleReplyChange}
            handleSaveReply={handleSaveReply}
          />
        );
      
      default:
        return null;
    }
  };

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Order ID</th>
            <th>Type</th>
            <th>Ticket ID</th>
            <th>Message</th>
            <th></th>
            <th>Change Status</th>
          </tr>
        </thead>
        <tbody>
          {PZUserTicketData.map((ticket, index) => (
            <tr key={index}>
              <td>{ticket.email}</td>
              {ticket.orderID ? <td className={`${styles.replyText} ${styles.tooltip}`} data-tooltip={ticket.orderID}>{ticket.orderID.slice(0, 4) + "..."}</td>:<td>-</td>}
              <td>{ticket.ticketType}</td>
              <td>{ticket.ticketID}</td>
              <td>{ticket.message}</td>
              <td className={styles.replyTextarea}>
  <button
    className={styles.sendButton}
    onClick={() => handleClickReplyButton(ticket.ticketID)}
  >
    Reply
  </button>
  {ticket.ticketReply && (
    <p className={`${styles.replyText} ${styles.tooltip}`} data-tooltip={ticket.ticketReply}>
      Replied
    </p>
  )}
</td>






              <td>
                <div className={styles.ticketChangeContainer}>
                  {ticket.ticketStatus}
                  <ButtonRade
                    customStyling={styles.statusSwitch}
                    onClick={() =>
                      handleClickChangeTicketStatus(
                        ticket.ticketID,
                        ticket.email
                      )
                    }
                  >
                    <img src={editIcon} className={styles.editIcon} alt="edit" />
                  </ButtonRade>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default PZServiceTicket;
