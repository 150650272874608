export const TX_USER_ENUM = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  phone: "Phone",
  id: "Aadhaar",
  panCard: "Pan Card",
  totalUSDPurchase: "Total USDT Purchase",
  kycStatus: "KYC Status",
  accountNumber: "Acc. Num",
  ifscCode: "IFSC",
  displayName: "Name",
};
