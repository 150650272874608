import React, { useEffect, useState } from "react";
import bcrypt from "bcryptjs";
import { doc, getDoc } from "firebase/firestore";
import { radexAuth, tetherxFirestore } from "../../firebase";
import styles from "./index.module.scss";
import { FULFILLMENT_ENUM } from "../../enums/fulfillmentEnum";
import SpinnerButton from "../../components/SpinnerButton/SpinnerButton";
import ButtonRade from "../../components/RadeButtons";
import refreshIcon from "../../assets/refrrshIcon.svg";
function TXFullfillment() {
  const [messages, setMessages] = useState([]); //state to store messages
  const [selectedMessages, setSelectedMessages] = useState([]); //state to store selected messages using check box
  const [isFetching, setIsFetching] = useState(false); //state to track API call
  const [accountDetails, setAccountDetails] = useState(null); //state to store account details of the receiver
  const [paymentReferenceError, setPaymentReferenceError] = useState(""); //state to store validation error for payment reference
  const [paymentRefs, setPaymentRefs] = useState([]); //state to store payment ref
  const [isProcessButtonDisabled, setIsProcessButtonDisabled] = useState(true); //state to track whether the process button should be disabled
  const [isFetchButtonDisabled, setIsFetchButtonDisabled] = useState(false); // state to track whether the fetch button should be disabled
  const [passwords, setPasswords] = useState({}); //state to track the entered password
  const [paymentStatus, setPaymentStatus] = useState(""); //state to tract payment status
  const [processButtonTimeout, setProcessButtonTimeout] = useState(null); //state to track the process button timeout
  const [fetchButtonTimeout, setFetchButtonTimeout] = useState(null); //state to track the fetch button timeout
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const MAX_DECIMAL_PLACE = 2; //variable that store maximum decimal place after a digit
  const [currentTime, setCurrentTime] = useState(new Date().getTime()); //track the current time
  useEffect(() => {
    console.log("isProcessButtonDisabled", isProcessButtonDisabled);
  }, isProcessButtonDisabled);
  // Classname for the proceesSelectedMessage button
  const processSelectedbuttonClass =
    selectedMessages.length && !isProcessButtonDisabled
      ? styles.processSelectedMessageButton // When the button is enabled
      : styles.processSelectedMessageButtonDisabled; // When the button is disabled
  const processFetchbuttonClass = !isFetchButtonDisabled
    ? styles.processFetchButton // When the button is enabled
    : styles.processFetchButtonDisabled; // When the button is disabled
  //Resetting the timer
  const resetProcessButtonTimer = () => {
    setIsProcessButtonDisabled(true);

    const processButtonTimer = setTimeout(() => {
      setIsProcessButtonDisabled(false);
    }, 30000);

    setProcessButtonTimeout(processButtonTimer);
  };
  // Update current time every second
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the timer on component unmount
  }, []);
  // Fetch account details from the firebase database using senderEmail and recieverAccountId (Uniquely generated id in the firebase)
  const fetchAccountDetails = async (senderEmail, receiverAccountId) => {
    try {
      // Construct the reference to the accountDetails document for the receiverAccountId
      const accountDetailsDocRef = doc(
        tetherxFirestore,
        `userData/${senderEmail}/userWalletAddressDetails/${receiverAccountId}`
      );

      // Get the account details using the document reference
      const accountDetailSnapshot = await getDoc(accountDetailsDocRef);

      if (!accountDetailSnapshot.exists()) {
        console.log(
          "No account details found for receiverAccountID:",
          receiverAccountId
        );
        return null;
      }

      const accountDetail = accountDetailSnapshot.data();

      return accountDetail;
    } catch (error) {
      console.error("Error fetching account details:", error);
      return null;
    }
  };

  // Fetch account details for each message's receiver_account_id
  useEffect(() => {
    if (messages.length > 0) {
      const fetchAccountDetailsForMessages = async () => {
        const accountDetailsArray = [];
        for (const message of messages) {
          const messageData = JSON.parse(message.Body);
          const messageBody = JSON.parse(messageData.Message);
          const receiverAccountId = messageBody.receiver_id;
          const senderEmail = messageBody.sender_email;
          // If there is no matching reciever_account_id
          if (!receiverAccountId) {
            setErrorMessage("Receiver account ID missing in message!");
            console.warn("Receiver account ID missing in message:", message);
            continue;
          }

          const accountDetail = await fetchAccountDetails(
            senderEmail,
            receiverAccountId
          );
          if (accountDetail) {
            accountDetailsArray.push({
              ...accountDetail,
              receiverAccountId,
            });
          }
        }
        setAccountDetails(accountDetailsArray);
      };

      fetchAccountDetailsForMessages();
    }
  }, [messages]);
  // Fetch messaged from the queue
  const fetchMessages = async () => {
    setErrorMessage("");
    if (processButtonTimeout) {
      clearTimeout(processButtonTimeout);
    }

    if (fetchButtonTimeout) {
      clearTimeout(fetchButtonTimeout);
    }

    resetProcessButtonTimer();

    try {
      setIsFetching(true);

      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
        },
      };

      const response = await fetch(
        process.env.REACT_APP_TETHERX_SERVER_URI +
          "/admin-fetch-usdt-orders-queue",
        requestOptions
      );

      if (!response.ok) {
        setErrorMessage("Failed to fetch messages from inr order queue!");
        throw new Error("Failed to fetch messages from inr order queue");
      }

      const data = await response.json();

      if (Array.isArray(data)) {
        setMessages(data);
      } else {
        setErrorMessage("Invalid response format!");
        console.error("Invalid response format:", data);
      }

      const newFetchButtonTimer = setTimeout(() => {
        setIsFetchButtonDisabled(false);
      }, 5000);

      setFetchButtonTimeout(newFetchButtonTimer);

      setIsProcessButtonDisabled(false);
      setIsFetching(false);

      // Fetch payment status for each transaction and update paymentStatuses state
    } catch (error) {
      setIsFetching(false);
      setErrorMessage("Error fetching messages:", error.message);
      console.error("Error fetching messages:", error);
    }
  };

  // Whenever a checkbox is selected or deselected, change the state variable
  // this is needed to pass as argument for process requests

  const handleCheckboxChange = (event, messageId, walletAddress) => {
    setErrorMessage("");
    if (event.target.checked) {
      setSelectedMessages((prevSelected) => [
        ...prevSelected,
        { messageId, walletAddress }, // Ensure this message ID matches correctly
      ]);
    } else {
      setSelectedMessages((prevSelected) =>
        prevSelected.filter((item) => item.messageId !== messageId)
      );
    }
  };
  // Function for processing selected messages in the queue
  // Note that there is a standard expiry of 1 hour (visibility timeout) for the rcptHndle keys (or messages in general).
  // After that, the user needs to refresh to get the new rcptHndle keys to send process request
  const processSelectedMessages = async () => {
    setErrorMessage("");
    setIsFetching(true);
    try {
      // Check if any payment reference field is empty
      for (const message of messages) {
        const isChecked = selectedMessages.includes(message.MessageId);

        if (isChecked) {
          const paymentReference = paymentRefs[message.MessageId];
          if (!paymentReference) {
            setPaymentReferenceError(
              "Please enter payment reference for all selected messages."
            );
            return;
          }
        }
      }
      // Properly declared and defined messageArray variable
      const messageArray = selectedMessages.map(
        ({ messageId, walletAddress }) => {
          const message = messages.find((msg) => msg.MessageId === messageId);
          const messageBody = JSON.parse(message.Body);
          const messageData = JSON.parse(messageBody.Message);
          return {
            receiptHandle: message.ReceiptHandle,
            sender_email: messageData.sender_email,
            tx_id: messageData.tx_id,
            inr_payment_ref: paymentRefs[message.MessageId] || "",
            usdt_amount: messageData.usdt_amount,
            receiverCryptoAddress: walletAddress, // Use the valid address or an empty string
            passwordHash: passwords[messageId] || "", // Include the hashed password
            inr_amount_received: messageData.inr_amount,
          };
        }
      );

      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      //to do: Add the url to process the messages
      const response = await fetch(
        process.env.REACT_APP_TETHERX_SERVER_URI +
          "/admin-process-usdt-orders-queue",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          },
          body: JSON.stringify({ messageArray: messageArray }),
        }
      );
      if (response.ok) {
        setSelectedMessages([]); // Clear the selectedMessages array

        await fetchMessages();

        setMessages((prevMessages) =>
          prevMessages.filter(
            (message) =>
              !messageArray.some(
                (msg) => msg.receiptHandle === message.ReceiptHandle
              )
          )
        );
        // Refresh messages after successful processing and deletion
        setTimeout(function () {
          setIsFetching(false);
        }, 10000);
      } else {
        const errorData = await response.json();
        setErrorMessage(`Error processing messages: ${errorData.error}`);
        setIsFetching(false);
      }
    } catch (error) {
      setIsFetching(false);
      setErrorMessage(`Error processing messages: ${error.message}`);
      console.error("Error processing messages:", error);
    }
  };

  // Whenever the input value in teh text area changes, set state
  const handlePaymentRefChange = (event, messageId) => {
    setErrorMessage("");
    const value = event.target.value;
    setPaymentReferenceError("");
    setPaymentRefs((prevValues) => ({ ...prevValues, [messageId]: value }));
  };
  //Function that handles the password input field
  const handlePasswordChange = async (event, messageId) => {
    const value = event.target.value;

    // Hash the password using bcrypt
    const hashedPassword = await bcrypt.hash(value, 10); // 10 is the number of salt rounds

    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [messageId]: hashedPassword,
    }));
  };
  //Function for deleting the unprocessed or ignored transaction
  const handleClickDeleteMessage = async () => {
    setIsFetching(true);

    try {
      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      for (const message of selectedMessages) {
        const { receiptHandle } = message;

        const response = await fetch(
          process.env.REACT_APP_TETHERX_SERVER_URI +
            "/admin-delete-usdt-orders-queue",

          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ receiptHandle }),
          }
        );

        if (response.ok) {
          console.log("Deleted the message");
        } else {
          console.error("Failed to delete message:", message);
        }
      }
    } catch (error) {
      console.error("Error deleting messages:", error);
    } finally {
      setIsFetching(false);
    }
  };
  // Function that handles the refresh button
  const handleClickRefresh = async (senderEmail, txId, messageId) => {
    try {
      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      const response = await fetch(
        process.env.REACT_APP_TETHERX_SERVER_URI +
          "/admin-get-transaction-payment-status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            sender_email: senderEmail,
            tx_id: txId,
          }),
        }
      );

      if (response.ok) {
        const paymentStatusData = await response.json();
        setPaymentStatus(paymentStatusData.payment_status);
        // Handle the payment status data as needed
        console.log("Payment Status Data:", paymentStatusData);
      } else {
        console.error("Failed to fetch payment status");
      }
    } catch (error) {
      console.error("Error refreshing payment status:", error);
    }
  };

  //Function to convert the time
  function formatTimeDifference(epochTime, currentTime) {
    const timeDifference = currentTime - epochTime; // Calculate time difference in milliseconds

    // Calculate hours, minutes, and seconds
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    // Format the time difference as hours:minutes:seconds
    const formattedTime = `${hours}h:${remainingMinutes}m:${remainingSeconds}s`;
    return formattedTime;
  }

  return (
    <div className={styles.tableContainer}>
      <button
        onClick={fetchMessages}
        disabled={isFetching || isFetchButtonDisabled}
        className={processFetchbuttonClass}
      >
        {FULFILLMENT_ENUM.fetchMessages}
      </button>
      <table>
        <thead>
          <tr>
            <th>{FULFILLMENT_ENUM.select}</th>
            <th>{FULFILLMENT_ENUM.email}</th>
            <th>{FULFILLMENT_ENUM.recievedUSDAmount}</th>
            <th>{FULFILLMENT_ENUM.recievedINRAmount}</th>
            <th>{FULFILLMENT_ENUM.recieverName}</th>
            <th>{FULFILLMENT_ENUM.selectedChain}</th>
            <th>{FULFILLMENT_ENUM.walletAddress}</th>
            <th>{FULFILLMENT_ENUM.time}</th>
            <th>{FULFILLMENT_ENUM.paymentReference}</th>
            <th>{FULFILLMENT_ENUM.password}</th>
            <th>{FULFILLMENT_ENUM.status}</th>

            <th></th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message) => {
            const messageData = JSON.parse(message.Body);
            const messageBody = JSON.parse(messageData.Message);
            // Find the account details for the current message's receiver_id
            const accountDetail =
              accountDetails?.find(
                (detail) => detail.receiverAccountId === messageBody.receiver_id
              ) || {};

            return (
              <tr key={message.MessageId}>
                <td>
                  <input
                    type="checkbox"
                    disabled={!accountDetails} // Disable checkboxes until account details are fetched
                    onChange={(e) =>
                      handleCheckboxChange(
                        e,
                        message.MessageId,
                        accountDetail?.walletAddress
                      )
                    }
                  />
                </td>
                <td>{messageBody.sender_email || "-"}</td>

                <td>
                  {(
                    messageBody.usdt_amount.amt /
                    10 ** messageBody.usdt_amount.decimals
                  ).toFixed(MAX_DECIMAL_PLACE) || "-"}
                </td>
                <td>
                  {(
                    messageBody.inr_amount.amt /
                    10 ** messageBody.inr_amount.decimals
                  ).toFixed(MAX_DECIMAL_PLACE) || "-"}
                </td>
                <td>{accountDetail?.nickName || "-"}</td>
                <td>{accountDetail?.selectedChain?.label || "-"}</td>

                <td className={styles.tooltip}>
                  {accountDetail?.walletAddress ? (
                    <span title={accountDetail.walletAddress}>
                      {accountDetail.walletAddress.slice(0, 3)}...
                      {accountDetail.walletAddress.slice(-2)}
                    </span>
                  ) : (
                    "-"
                  )}
                </td>
                <td>
                  {messageBody.created_at
                    ? formatTimeDifference(messageBody.created_at, currentTime)
                    : "-"}
                </td>
                <td>
                  <input
                    className={styles.inputPaymentReference}
                    type="text"
                    placeholder="Enter the payment reference"
                    value={paymentRefs[message.MessageId] || ""}
                    onChange={(e) =>
                      handlePaymentRefChange(e, message.MessageId)
                    }
                  />
                </td>
                <td>
                  <input
                    className={styles.inputPaymentReference}
                    type="password"
                    placeholder="Enter the password"
                    onChange={(e) => handlePasswordChange(e, message.MessageId)}
                  />
                </td>
                <td>
                  {paymentStatus ? paymentStatus : messageBody.tx_status || "-"}
                </td>
                <td>
                  {/* Refresh button */}
                  <ButtonRade
                    customStyling={styles.refreshButton}
                    onClick={() =>
                      handleClickRefresh(
                        messageBody.sender_email,
                        messageBody.tx_id,
                        message.MessageId
                      )
                    }
                  >
                    <img
                      src={refreshIcon}
                      alt="refresh"
                      className={styles.refresh}
                    />
                  </ButtonRade>
                </td>
                <td>
                  <ButtonRade
                    customStyling={styles.deleteButton}
                    onClick={handleClickDeleteMessage}
                  >
                    Remove
                  </ButtonRade>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <button
        onClick={processSelectedMessages}
        disabled={!selectedMessages.length || isProcessButtonDisabled}
        className={processSelectedbuttonClass}
      >
        {isFetching ? (
          <div className={styles.spinnerDiv}>
            <SpinnerButton />
          </div>
        ) : (
          FULFILLMENT_ENUM.processSelectedMessage
        )}
      </button>
      {paymentReferenceError && (
        <p className={styles.validationError}>{paymentReferenceError}</p>
      )}
      {isProcessButtonDisabled && !isFetching && (
        <p className={styles.sessionExpiredWarning}>
          {FULFILLMENT_ENUM.sesionExpired}
        </p>
      )}
      {errorMessage && <p className={styles.validationError}>{errorMessage}</p>}
    </div>
  );
}

export default TXFullfillment;
