import React, { useState, useEffect } from "react";
import { HEADER_ENUM } from "../../enums/headerEnum";
import styles from "./index.module.scss";
import { radexAuth } from "../../firebase";
const SupplySummary = () => {
  const [treasuryBalanceInUSDT, setTreasuryBalanceInUSDT] = useState(null); //state to set treasury balance in payezy
  const [treasuryBalanceInINR, setTreasuryBalanceInINR] = useState(null); //state to set treasury balance in tetherx
  //state to set USDT to INR conversion
  const MAX_DECIMAL_PLACE = 2;

  // Fetching treasury bakance in tetherx

  useEffect(() => {
    const getTreasuryBalanceUSDT = async () => {
      try {
        // Obtain ID token
        const idToken = await radexAuth.currentUser.getIdToken(
          /* forceRefresh */ true
        );

        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          },
        };

        const response = await fetch(
          process.env.REACT_APP_TETHERX_SERVER_URI +
            `/admin-get-usdt-treasury-balance`,
          requestOptions
        );

        const result = await response.json();
        setTreasuryBalanceInUSDT(
          result.balanceAmount / 10 ** result.balanceDecimals
        );
      } catch (error) {
        console.log("Error fetching treasury balance:", error);
      }
    };

    if (radexAuth.currentUser) {
      getTreasuryBalanceUSDT();
    } else {
      console.log("No authenticated user");
    }
  }, [radexAuth.currentUser]);
  // Fetching treasury bakance in payezy

  useEffect(() => {
    const getTreasuryBalanceINR = async () => {
      try {
        // Obtain ID token
        const idToken = await radexAuth.currentUser.getIdToken(
          /* forceRefresh */ true
        );

        var requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          },
        };

        const response = await fetch(
          process.env.REACT_APP_PAYEZY_SERVER_URI +
            `/admin-get-inr-treasury-balance`,
          requestOptions
        );

        const result = await response.json();
        setTreasuryBalanceInINR(
          result.balanceAmount / 10 ** result.balanceDecimals
        );
      } catch (error) {
        console.log("Error fetching treasury balance:", error);
      }
    };

    if (radexAuth.currentUser) {
      getTreasuryBalanceINR();
    } else {
      console.log("No authenticated user");
    }
  }, [radexAuth.currentUser]);

  return (
    <div>
      <div className={styles.PZTransfersContainers}>
        {(treasuryBalanceInUSDT === 0 || treasuryBalanceInUSDT) && (
          <div>
            <p className={styles.supplyHeadings}>{HEADER_ENUM.payezyBalance}</p>
            <p className={styles.supplyValues}>
              {treasuryBalanceInUSDT.toFixed(MAX_DECIMAL_PLACE)}
            </p>
          </div>
        )}
        {(treasuryBalanceInINR === 0 || treasuryBalanceInINR) && (
          <div>
            <p className={styles.supplyHeadings}>
              {HEADER_ENUM.tetherXTreasuryBalance}
            </p>
            <p className={styles.supplyValues}>
              {treasuryBalanceInINR.toFixed(MAX_DECIMAL_PLACE)}
            </p>
          </div>
        )}
        <div>
          <p className={styles.supplyHeadings}>{HEADER_ENUM.totalSendOrders}</p>
          <p className={styles.supplyValues}>
            {HEADER_ENUM.totalSendOrdersValue}
          </p>
        </div>
        <div>
          <p className={styles.supplyHeadings}>{HEADER_ENUM.totalBuyOrders}</p>
          <p className={styles.supplyValues}>
            {HEADER_ENUM.totalBuyOrdersValue}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SupplySummary;
