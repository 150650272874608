import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { PZ_USER_ENUM } from "../../enums/PZUserEnum";
import { payezyFirestore } from "../../firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import attachmentLogo from "../../assets/attatchmentLogo.svg";
import styles from "./index.module.scss";
import KYCStatusSwitchPopUp from "../KYCStatusSwitchPopUp/KYCStatusSwitchPopUp";
import Spinner from "../../components/Spinner/Spinner";
const PZUser = () => {
  const [PZUserdata, setPZUserData] = useState([]); //state to store the user data
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [modalIsOpen, setModalIsOpen] = useState(false); //state to open and close the modal
  const [pageSize, setPageSize] = useState(10); //state to store the page size for pagination
  const [searchTerm, setSearchTerm] = useState(""); //state to store the serach term
  const [selectedKYCId, setSelectedKYCId] = useState(null); //state to store the selected KYC Id
  const [loadingStates, setLoadingStates] = useState({}); // Track loading state for each row
  // Function that handles the saerch bar
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  //Fetch he userData collection to fetch the user emails that has support tickets
  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const result = querySnapshot.docs.map((doc) => doc.data());
      setPZUserData(result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPZUserData();
  }, []);
  // Function to handle the filtering of data using the email
  const filteredData = useMemo(() => {
    return PZUserdata.filter(
      (post) =>
        post.email &&
        post.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [PZUserdata, searchTerm]);

  //Function to handle the page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //Function to switch the KYC status
  const handleClickStatusSwitch = (kycId) => {
    setSelectedKYCId(kycId);
    setModalIsOpen(true);
  };
  //Function to update the KYC status in the firebase
  const handleKYCStatusUpdate = async (status) => {
    try {
      if (!selectedKYCId) {
        console.log("No KYC document selected.");
        return;
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: true, // Set loading state for the selected row to true
      }));

      const kycDocRef = doc(payezyFirestore, "userData", selectedKYCId);
      await updateDoc(kycDocRef, { KYCStatus: status });
      console.log("KYC status updated successfully!");

      await getPZUserData();

      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: false, // Set loading state for the selected row to false
      }));
    } catch (error) {
      console.log("Error updating KYC status:", error);
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: false, // Set loading state for the selected row to false in case of an error
      }));
    }
  };
  //Function for pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);
  if (paginatedData.length < 1) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    return (
      <div>
        <div className={styles.transferOrders}>
          <div className={styles.refreshButtonContainer}>
            <div className={styles.searchBarDiv}>
              <input
                type="text"
                className={styles.searchBar}
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
              <img
                src={searchIcon}
                className={styles.searchIcon}
                alt="searchIcon"
              />
            </div>
            <ButtonRade
              customStyling={styles.refreshButton}
              onClick={getPZUserData}
            >
              {" "}
              <img
                src={refreshIcon}
                alt="refreshIcon"
                className={styles.refreshIcon}
              />
            </ButtonRade>
          </div>
          <div className={styles.searchContainer}>
            <div className={styles.search}>
              Show
              <input
                type="number"
                className={styles.searchPage}
                min={1}
                value={pageSize}
                onChange={(e) => setPageSize(parseInt(e.target.value))}
              />
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredData.length}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                {/* <th>{PZ_USER_ENUM.firstName}</th>
                <th>{PZ_USER_ENUM.lastName}</th> */}
                <th>{PZ_USER_ENUM.email}</th>
                <th>{PZ_USER_ENUM.displayName}</th>
                {/* <th>{PZ_USER_ENUM.phone}</th>
                <th>{PZ_USER_ENUM.idFrontSide}</th>
                <th>Holding ID[F]</th>
                <th>Holding ID[B]</th>
                <th>{PZ_USER_ENUM.idBackSide}</th> */}
                <th>{PZ_USER_ENUM.totalAmountTransfers}</th>
                <th>{PZ_USER_ENUM.totalhashTransfers}</th>
                <th>{PZ_USER_ENUM.kycStatus}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((post) => (
                <tr key={post.email}>
                  {/* <td>{post.firstName || "-"}</td>
                  <td>{post.lastName || "-"}</td> */}
                  <td>{post.email || "-"}</td>
                  <td>{post.displayName || "-"}</td>
                  {/* <td>{post.phoneNumber || "-"}</td>
                  <td>
                    {post.idBackSide ? (
                      <a
                        href={post.idBackSide}
                        target="_blank"
                        className={styles.idFrontSideLink}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={attachmentLogo}
                          className={styles.attachmentLogo}
                          alt="attatchment"
                        />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {post.idFrontSide ? (
                      <a
                        href={post.idFrontSide}
                        target="_blank"
                        className={styles.idFrontSideLink}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={attachmentLogo}
                          className={styles.attachmentLogo}
                          alt="attatchment"
                        />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {post.AadharCardHold ? (
                      <a
                        href={post.AadharCardHold}
                        target="_blank"
                        className={styles.idFrontSideLink}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={attachmentLogo}
                          className={styles.attachmentLogo}
                          alt="attatchment"
                        />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {post.PancardHold ? (
                      <a
                        href={post.PancardHold}
                        target="_blank"
                        className={styles.idFrontSideLink}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={attachmentLogo}
                          className={styles.attachmentLogo}
                          alt="attatchment"
                        />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td> */}

                  <td>$300</td>
                  <td>5</td>
                  <td>
                    {loadingStates[post.email] ? (
                      <div className={styles.spinnerOverlay}>
                        <div className={styles.spinnerContainer} />
                      </div>
                    ) : (
                      post.KYCStatus
                    )}
                  </td>
                  <td>
                    <ButtonRade
                      customStyling={`${styles.statusSwitch} ${
                        loadingStates[post.email] ? styles.disabledButton : ""
                      }`}
                      disabled={loadingStates[post.email]} // Disable the button when loading is true
                      onClick={() => handleClickStatusSwitch(post.email)}
                    >
                      Change Status
                    </ButtonRade>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          <KYCStatusSwitchPopUp
            setModalIsOpen={setModalIsOpen}
            handleKYCStatusUpdate={handleKYCStatusUpdate}
            selectedKYCId={selectedKYCId}
          />
        </Modal>
      </div>
    );
  }
};

export default PZUser;
