import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import searchIcon from "../../assets/searchIcon.svg";
import { TX_USER_ENUM } from "../../enums/TXUserEnum";
import KYCStatusSwitchPopUp from "../KYCStatusSwitchPopUp/KYCStatusSwitchPopUp";
import { tetherxFirestore } from "../../firebase";
import attachmentLogo from "../../assets/attatchmentLogo.svg";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import styles from "./index.module.scss";
import Spinner from "../../components/Spinner/Spinner";
const TXUser = () => {
  const [TXUserData, setTXUserData] = useState([]); //state to store the TX user data
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [pageSize, setPageSize] = useState(10); //state for storing the page size for pagination
  const [searchTerm, setSearchTerm] = useState(""); //state for storing the search term for search box
  const [selectedKYCId, setSelectedKYCId] = useState(null); //state for storing the selected KYC id
  const [loadingStates, setLoadingStates] = useState({}); // Track loading state for each row
  const [modalIsOpen, setModalIsOpen] = useState(false); //state for opening and closing the modal
  // Function that handles the search bar
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };
  //Fetch the user data from the firebase using userData collection
  const getTXUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(tetherxFirestore, "userData")
      );
      const result = [];

      for (const doc of querySnapshot.docs) {
        const userData = doc.data();

        // Fetch userAccountDetails collection within the userData document
        const accountDetailsSnapshot = await getDocs(
          collection(doc.ref, "userAccountDetails")
        );
        const accountDetails = accountDetailsSnapshot.docs.map((doc) =>
          doc.data()
        );
        // Merge user data and account details
        const userDataWithAccountDetails = {
          ...userData,
          accountDetails: accountDetails,
        };

        result.push(userDataWithAccountDetails);
      }

      setTXUserData(result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTXUserData();
  }, []);
  //Function to filter the data  using email
  const filteredData = useMemo(() => {
    return TXUserData.filter(
      (post) =>
        post.email &&
        post.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [TXUserData, searchTerm]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  //Function for handling the pagination
  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredData.slice(startIndex, endIndex);
  }, [currentPage, filteredData, pageSize]);
  const handleClickStatusSwitch = (kycId) => {
    setSelectedKYCId(kycId);
    setModalIsOpen(true);
  };
  //Function that handles the updation of KYX status in the firestore
  const handleKYCStatusUpdate = async (status) => {
    try {
      if (!selectedKYCId) {
        console.log("No KYC document selected.");
        return;
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: true, // Set loading state for the selected row to true
      }));

      const kycDocRef = doc(tetherxFirestore, "userData", selectedKYCId);
      await updateDoc(kycDocRef, { KYCStatus: status });
      console.log("KYC status updated successfully!");

      await getTXUserData();

      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: false, // Set loading state for the selected row to false
      }));
    } catch (error) {
      console.log("Error updating KYC status:", error);
      setLoadingStates((prevState) => ({
        ...prevState,
        [selectedKYCId]: false, // Set loading state for the selected row to false in case of an error
      }));
    }
  };
  if (paginatedData.length < 1) {
    return (
      <>
        <Spinner />
      </>
    );
  } else {
    return (
      <div>
        <div className={styles.transferOrders}>
          <div className={styles.refreshButtonContainer}>
            <div className={styles.searchBarDiv}>
              <input
                type="text"
                className={styles.searchBar}
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
              />
              <img
                src={searchIcon}
                className={styles.searchIcon}
                alt="searchIcon"
              />
            </div>
            <ButtonRade
              customStyling={styles.refreshButton}
              onClick={getTXUserData}
            >
              <img
                src={refreshIcon}
                alt="refreshIcon"
                className={styles.refreshIcon}
              />
            </ButtonRade>
          </div>
          <div className={styles.searchContainer}>
            <div className={styles.search}>
              Show
              <input
                type="number"
                className={styles.searchPage}
                min={1}
                value={pageSize}
                onChange={(e) => setPageSize(parseInt(e.target.value))}
              />
            </div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={filteredData.length}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table>
            <thead>
              <tr>
                {/* <th>{TX_USER_ENUM.firstName}</th>
                <th>{TX_USER_ENUM.lastName}</th> */}
                <th>{TX_USER_ENUM.email}</th>
                <th>{TX_USER_ENUM.displayName}</th>
                {/* <th>{TX_USER_ENUM.phone}</th>
                <th>{TX_USER_ENUM.id}</th>
                <th>Aadhar Backside</th>
                <th>Holding ID</th>
                <th>{TX_USER_ENUM.panCard}</th> */}

                {/* To do for INRT payment */}
                {/* <th>{TX_USER_ENUM.accountNumber}</th>
              <th>{TX_USER_ENUM.ifscCode}</th> */}
                <th>{TX_USER_ENUM.totalUSDPurchase}</th>
                <th>{TX_USER_ENUM.kycStatus}</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((post) => (
                <tr key={post.email}>
                  {/* <td>{post.firstName || "-"}</td>
                  <td>{post.lastName || "-"}</td> */}
                  <td>{post.email || "-"}</td>
                  <td>{post.displayName || "-"}</td>
                  {/* <td>{post.phoneNumber || "-"}</td>

                  <td>
                    {post.AadharCard ? (
                      <>
                        <a
                          href={post.AadharCard}
                          target="_blank"
                          className={styles.idFrontSideLink}
                          rel="noopener noreferrer"
                        >
                          <img
                            src={attachmentLogo}
                            className={styles.attachmentLogo}
                            alt="attachment"
                          />
                        </a>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {post.AadharBackside ? (
                      <>
                        <a
                          href={post.AadharBackside}
                          target="_blank"
                          className={styles.idFrontSideLink}
                          rel="noopener noreferrer"
                        >
                          <img
                            src={attachmentLogo}
                            className={styles.attachmentLogo}
                            alt="attachment"
                          />
                        </a>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {post.AadharCardHold ? (
                      <>
                        <a
                          href={post.AadharCardHold}
                          target="_blank"
                          className={styles.idFrontSideLink}
                          rel="noopener noreferrer"
                        >
                          <img
                            src={attachmentLogo}
                            className={styles.attachmentLogo}
                            alt="attachment"
                          />
                        </a>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {post.Pancard ? (
                      <a
                        href={post.Pancard}
                        target="_blank"
                        className={styles.idFrontSideLink}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={attachmentLogo}
                          className={styles.attachmentLogo}
                          alt="attatchment"
                        />
                      </a>
                    ) : (
                      "-"
                    )}
                  </td> */}
                  {/* To do for INRT payment */}
                  {/* <td>
                  {post.accountDetails.length > 0
                    ? post.accountDetails[0].accountNumber
                    : "-"}
                </td>
                <td>
                  {post.accountDetails.length > 0
                    ? post.accountDetails[0].ifscCode
                    : "-"}
                </td> */}
                  <td>4800</td>
                  <td>
                    {loadingStates[post.email] ? (
                      <div className={styles.spinnerOverlay}>
                        <div className={styles.spinnerContainer} />
                      </div>
                    ) : (
                      post.KYCStatus
                    )}
                  </td>
                  <td>
                    <ButtonRade
                      customStyling={`${styles.statusSwitch} ${
                        loadingStates[post.email] ? styles.disabledButton : ""
                      }`}
                      disabled={loadingStates[post.email]} // Disable the button when loading is true
                      onClick={() => handleClickStatusSwitch(post.email)}
                    >
                      Change Status
                    </ButtonRade>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Modal
          isOpen={modalIsOpen}
          overlayClassName={styles.popupOverlay}
          className={styles.popupContent}
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
        >
          <KYCStatusSwitchPopUp
            setModalIsOpen={setModalIsOpen}
            handleKYCStatusUpdate={handleKYCStatusUpdate}
            selectedKYCId={selectedKYCId}
          />
        </Modal>
      </div>
    );
  }
};

export default TXUser;
