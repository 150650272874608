import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import StatusSwitchPopUp from "../StatusSwitchPopUp/StatusSwitchPopUp";
import { collection, getDocs, doc } from "firebase/firestore";
import { payezyFirestore, radexAuth } from "../../firebase";
import SelectBox from "../../components/SelectBox/SelectBox";
const PZTransfers = () => {
  const [userEmails, setUserEmails] = useState([]); //state to store selected user email
  const [selectedUserEmails, setSelectedUserEmails] = useState({
    value: "",
    label: "",
  });
  const [accountDetails, setAccountDetails] = useState([]); //  state for storing the account details
  const [modalIsOpen, setModalIsOpen] = useState(false); //state to open and close the modal
  const [PZUserdata, setPZUserData] = useState([]); //state to store the user data
  const [transferHistoryData, setTranferHistoryData] = useState([]); //  state for storing transfer history data
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page for pagination
  const [pageSizePagination, setPageSizePagination] = useState(10); //satte store the page size for pagination
  const MAX_DECIMAL_PLACE = 2; //Variable that defines maximum decimal place
  // Function that handles the search bar
  const handleSearch = (e) => {
    const newSize = parseInt(e.target.value);
    if (newSize >= 1) {
      setPageSizePagination(newSize);
      setCurrentPage(1);
    }
  };
  //`Function for pagination
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSizePagination;
    const lastPageIndex = firstPageIndex + pageSizePagination;
    return transferHistoryData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSizePagination, transferHistoryData]);
  //Fetch the userData collection from the firebase to fetch the user details
  const getPZUserData = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(payezyFirestore, "userData")
      );
      const result = querySnapshot.docs.map((doc) => doc.data());
      setPZUserData(result);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getPZUserData();
  }, []);
  //Fetch the account details like IFSC code, account number, etc
  useEffect(() => {
    async function fetchUserDetails() {
      if (selectedUserEmails && selectedUserEmails.value) {
        // Fetch the userData collection using profile email as a reference
        const userDataRef = doc(
          payezyFirestore,
          "userData",
          selectedUserEmails.value
        );
        // Fetch the accountDetails collection using profile email as a reference
        const accountDetailsRef = collection(userDataRef, "accountDetails");
        const querySnapshot = await getDocs(accountDetailsRef);
        const accountDetailsArray = [];
        // Store the account details into accountDetails state
        querySnapshot.forEach((doc) => {
          const accountDetail = doc.data();
          accountDetailsArray.push({
            ...accountDetail,
            recipientAccountID: doc.id,
          });
        });
        // Store the account details in component state
        setAccountDetails(accountDetailsArray);
      }
    }
    fetchUserDetails();
    // Do any other necessary processing based on the URL parameters
  }, [selectedUserEmails]);

  // Function to extract user emails from PZUserData and update the state
  useEffect(() => {
    const extractUserEmails = () => {
      const emails = PZUserdata.map((user) => ({
        value: user.email,
        label: user.email,
      }));
      setUserEmails(emails);
    };

    // Call the extractUserEmails function when PZUserData is updated
    extractUserEmails();
  }, [PZUserdata]);

  const handleClickStatusSwitch = () => {
    setModalIsOpen(true);
  };

  // Fetch the transaction details from back end
  useEffect(() => {
    if (selectedUserEmails && selectedUserEmails.value) {
      const getTransactionHistoryData = async () => {
        const transferDetails = {
          email: selectedUserEmails.value, // User email
        };
        const idToken = await radexAuth.currentUser.getIdToken(
          /* forceRefresh */ true
        );

        var requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`, // Include the ID token in the headers

            // Add other headers if needed
          },
          body: JSON.stringify(transferDetails),
        };

        try {
          const response = await fetch(
            process.env.REACT_APP_PAYEZY_SERVER_URI +
              `/admin-get-user-transaction-history`,
            requestOptions
          );

          if (response.ok) {
            // Request was successful
            const responseData = await response.json();
            // store the trasnfer history data
            setTranferHistoryData(responseData.extractedDetails);
          } else {
            // Handle error response
            const errorData = await response.json();
            // Handle the error data
            console.log("errorData, purchase details:", errorData);
          }
        } catch (error) {
          // Handle network or fetch-related errors
          console.error("Error:", error);
        }
      };
      getTransactionHistoryData();
      // Fetch data every 10 minutes
      const interval = setInterval(() => {
        getTransactionHistoryData();
      }, 10 * 60 * 1000);

      // Clean up the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [selectedUserEmails]);
  // Function that format the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }

  return (
    <>
      <div className={styles.emailDropDown}>
        <SelectBox
          placeHolder="Select an email"
          options={userEmails}
          onChange={(selectedValue) => {
            setSelectedUserEmails(selectedValue);
            // Handle the selected user email if needed
          }}
          setSelectedValue={setSelectedUserEmails}
          selectedValue={selectedUserEmails}
          errorDismissOnclick={() => {}}
        />
      </div>
      {selectedUserEmails.value ? (
        <div>
          <div className={styles.transferOrders}>
            <div className={styles.refreshButtonContainer}>
              {PZ_TRANSFER_ENUM.transferOrders}{" "}
              <ButtonRade
                customStyling={styles.refreshButton}
                // onClick={getData}
              >
                <img
                  src={refreshIcon}
                  alt="refreshIcon"
                  className={styles.refreshIcon}
                />
              </ButtonRade>
            </div>
            <div className={styles.searchContainer}>
              <div className={styles.search}>
                Show
                <input
                  type="number"
                  className={styles.searchPage}
                  onChange={handleSearch}
                  max="50"
                />
                <Pagination
                  className="pagination-bar"
                  currentPage={currentPage}
                  totalCount={transferHistoryData.length}
                  pageSize={pageSizePagination}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          </div>
          <div class="tableOuterContainer">
            {" "}
            <div className={styles.tableContainer}>
              <table>
                <thead>
                  <tr>
                    <th className={styles.date}>{PZ_TRANSFER_ENUM.date}</th>
                    <th>{PZ_TRANSFER_ENUM.transferID}</th>
                    <th>{PZ_TRANSFER_ENUM.recipient}</th>
                    <th>{PZ_TRANSFER_ENUM.receives} </th>
                    <th>{PZ_TRANSFER_ENUM.accountNumber}</th>
                    <th>{PZ_TRANSFER_ENUM.IFSCCode}</th>
                    <th>{PZ_TRANSFER_ENUM.stripeStatus}</th>

                    <th>{PZ_TRANSFER_ENUM.phoneNumber}</th>
                    <th>{PZ_TRANSFER_ENUM.statusSwitch}</th>
                  </tr>
                </thead>
                {currentTableData.length > 0 && (
                  <tbody>
                    {currentTableData.map((post) => (
                      <tr key={post.order_id.S}>
                        <td>
                          <span className={styles.date}>
                            {formatEpochTime(Number(post.created_at))}
                          </span>
                        </td>
                        <td>{post.order_id.S}</td>
                        <td>
                          {" "}
                          {accountDetails.find(
                            (detail) =>
                              detail.recipientAccountID ===
                              post.receiver_account_id
                          )?.fullName && (
                            <div className={styles.recipientContainer}>
                              {
                                accountDetails.find(
                                  (detail) =>
                                    detail.recipientAccountID ===
                                    post.receiver_account_id
                                ).fullName
                              }
                            </div>
                          )}
                        </td>

                        <td>
                          {Number(post.inr_amount).toFixed(MAX_DECIMAL_PLACE)}
                        </td>
                        <td>
                          {" "}
                          {accountDetails.find(
                            (detail) =>
                              detail.recipientAccountID ===
                              post.receiver_account_id
                          )?.accountNumber && (
                            <div className={styles.recipientContainer}>
                              {
                                accountDetails.find(
                                  (detail) =>
                                    detail.recipientAccountID ===
                                    post.receiver_account_id
                                ).accountNumber
                              }
                            </div>
                          )}
                        </td>
                        <td>
                          {" "}
                          {accountDetails.find(
                            (detail) =>
                              detail.recipientAccountID ===
                              post.receiver_account_id
                          )?.IFSCCode && (
                            <div className={styles.recipientContainer}>
                              {
                                accountDetails.find(
                                  (detail) =>
                                    detail.recipientAccountID ===
                                    post.receiver_account_id
                                ).IFSCCode
                              }
                            </div>
                          )}
                        </td>
                        <td>
                          {post.tx_status === "payment_received" && (
                            <>Awaiting Settl.</>
                          )}
                          {post.tx_status === "created" ||
                          post.tx_status === "payment_init" ? (
                            <>Processing</>
                          ) : null}

                          {post.tx_status === "payment_rejected" ||
                          post.tx_status === "delivery_rejected" ? (
                            <>Cancelled</>
                          ) : null}
                          {post.tx_status === "delivery_complete" && (
                            <>Completed</>
                          )}
                        </td>

                        <td>
                          {accountDetails.find(
                            (detail) =>
                              detail.recipientAccountID ===
                              post.receiver_account_id
                          )?.phoneNumber && (
                            <div className={styles.recipientContainer}>
                              {
                                accountDetails.find(
                                  (detail) =>
                                    detail.recipientAccountID ===
                                    post.receiver_account_id
                                ).phoneNumber
                              }
                            </div>
                          )}
                        </td>

                        <td>
                          {post.tx_status === "payment_received" && (
                            <ButtonRade
                              customStyling={styles.statusSwitch}
                              onClick={handleClickStatusSwitch}
                            >
                              Awaiting Settl.
                            </ButtonRade>
                          )}
                          {post.tx_status === "created" ||
                          post.tx_status === "payment_init" ? (
                            <ButtonRade
                              customStyling={styles.statusSwitch}
                              onClick={handleClickStatusSwitch}
                            >
                              Processing
                            </ButtonRade>
                          ) : null}

                          {post.tx_status === "payment_rejected" ||
                          post.tx_status === "delivery_rejected" ? (
                            <ButtonRade
                              customStyling={styles.statusSwitch}
                              onClick={handleClickStatusSwitch}
                            >
                              Cancelled
                            </ButtonRade>
                          ) : null}
                          {post.tx_status === "delivery_complete" && (
                            <ButtonRade
                              customStyling={styles.statusSwitch}
                              onClick={handleClickStatusSwitch}
                            >
                              Completed
                            </ButtonRade>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            overlayClassName={styles.popupOverlay}
            className={styles.popupContent}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
          >
            <StatusSwitchPopUp setModalIsOpen={setModalIsOpen} />
          </Modal>
        </div>
      ) : (
        <div className={styles.selectEmail}></div>
      )}
    </>
  );
};

export default PZTransfers;
