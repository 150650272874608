import React, { useState } from "react";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import { TX_ALPYNE_ENUM } from "../../enums/TxAlpyneEnum";
const MAX_DECIMAL_PLACE = 2;
const UsdtSellConfirmModal = ({
  setModalIsOpen,
  amountInUsdt,
  amountInInr,
  inrLivePrice,
  inrLastSellPrice,
  setLoading,
}) => {
  // Function to handlle cancel button
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };
  // Function to convert the current date and time
  function formatCurrentDateTime() {
    const date = new Date();

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedDateTime = `${dateStr} ${timeStr}`;

    return formattedDateTime;
  }
  // Function to handlle confirm button
  const handleClickConfirmButton = () => {
    if (!amountInUsdt || !inrLivePrice) {
      return;
    }

    const newTransaction = {
      usdtAmount: amountInUsdt,
      inrAmount: amountInInr,
      dateTime: formatCurrentDateTime(),
      sellPrice: inrLivePrice,
      status: "Pending",
    };

    fetch(
      process.env.REACT_APP_ALPYNE_INR_PRICE + "/usdt-transaction-history",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTransaction),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Transaction added:", data);
        // If you want to update the UI or perform any other action, you can do it here
      })
      .catch((error) => {
        console.error("Error adding transaction:", error);
      });
    setModalIsOpen(false);
    setLoading(true);
  };

  return (
    <div className={styles.modalMainContainer}>
      <div className={styles.changeStatus}>
        {TX_ALPYNE_ENUM.sellUsdtConfirmContent}
      </div>
      <div className={styles.inputBoxContainer}>
        <div className={styles.inputBoxContents}>
          <div className={styles.dollarSymbol}>$</div>
          <div className={styles.usdtAmount}>
            {Number(amountInUsdt).toFixed(MAX_DECIMAL_PLACE)}
          </div>
        </div>
        <div className={styles.usdt}>{TX_ALPYNE_ENUM.usdt}</div>
      </div>
      <div className={styles.inputBoxContainer}>
        <div className={styles.inputBoxContents}>
          <div className={styles.dollarSymbol}>₹</div>
          <div className={styles.usdtAmount}>
            {Number(amountInInr).toFixed(MAX_DECIMAL_PLACE)}
          </div>
        </div>
        <div className={styles.usdt}>{TX_ALPYNE_ENUM.inr}</div>
      </div>

      <div className={styles.livePriceContainer}>
        <div className={styles.livePriceKey}>{TX_ALPYNE_ENUM.livePrice}:</div>
        <div className={styles.livePriceValue}>
          {Number(inrLivePrice).toFixed(MAX_DECIMAL_PLACE)}
        </div>
      </div>
      <div className={styles.lastPriceContainer}>
        <div className={styles.lastPriceKey}>
          {TX_ALPYNE_ENUM.lastSellPrice}:
        </div>
        <div className={styles.lastPriceValue}>
          {inrLastSellPrice
            ? Number(inrLastSellPrice).toFixed(MAX_DECIMAL_PLACE)
            : "-"}
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <ButtonRade
          customStyling={styles.cancelButton}
          onClick={handleClickCancelButton}
        >
          Cancel
        </ButtonRade>
        <ButtonRade
          customStyling={styles.updateButton}
          onClick={handleClickConfirmButton}
        >
          Confirm
        </ButtonRade>
      </div>
    </div>
  );
};

export default UsdtSellConfirmModal;
