import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useMediaQuery } from "react-responsive";
import PZTransfers from "./pages/PZTransfers/PZTransfers";
import LoginWithGoogle from "./pages/LoginWithGoogle/LoginWithGoogle";
import { AppContext } from "./context";
import Header from "./components/header/header";
import { NavLink, useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Spinner from "./components/Spinner/Spinner";
import styles from "./styles/app.module.scss";
import PZUsers from "./pages/PZUsers/PZUsers";
import PZST from "./pages/PZST/PZST";
import TXOrders from "./pages/TXOrders/TXOrders";
import TXUsers from "./pages/TXUsers/TXUsers";
import TXST from "./pages/TXST/TXST";
import TXUSDT from "./pages/TXUSDT/TXUSDT";
import PZFulfillmentß from "./pages/PZFullfillment/PZFullfillment";
import TXServiceTicket from "./pages/TXServiceTicket/TXServiceTicket";
import TXFullfillment from "./pages/TXFullfillment/TXFullfillment";
import PZServiceTicket from "./pages/PZServiceTicket/PZServiceTicket";
import TxAlpyne from "./pages/TxAlpyne/TxAlpyne";
function App() {
  const [loading, setLoading] = useState(false); // Loading state
  const [sendLoginPageNumber, setSendLoginPageNumber] = useState(false); // State variables for storing google login page number
  const [radexFlow, setRadexFlow] = useState(null); //state that represent the radex flow
  const [onClickLoginButton, setOnClickLoginButton] = useState(false);
  const [profileEmail, setProfileEmail] = useState(false); // State for profile email
  const [profile, setProfile] = useState([]); // State for profile information
  const location = useLocation(); //variable for detecting the location of the app
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" }); // state that defines the mobile view
  //persisting the state using firabese onAuthStateChanged method
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      // if connected email is team@rariti.io
      if (user.email === "team@rariti.io") {
        setProfileEmail(user.email);
      } else {
        setProfileEmail(null);
      }
    });
    return () => unsubscribe();
  }, []);
  //Function for Radex app google login flow
  const radexAppLoginFlow = () => {
    switch (sendLoginPageNumber) {
      case 0:
        return (
          <LoginWithGoogle
            profileEmail={profileEmail}
            setProfileEmail={setProfileEmail}
            profile={profile}
            setProfile={setProfile}
            onClickLoginButton={setOnClickLoginButton}
            setOnClickLoginButton={setOnClickLoginButton}
            radexFlow={radexFlow}
            setRadexFlow={setRadexFlow}
          />
        );

      default:
        return null;
    }
  };
  return (
    <>
      <AppContext.Provider
        value={{
          isMobile,
          radexFlow,
          setRadexFlow,
          sendLoginPageNumber,
          setSendLoginPageNumber,
          setOnClickLoginButton,
          loading,
          setLoading,
          profileEmail,
        }}
      >
        <Header
          profileEmail={profileEmail}
          setProfileEmail={setProfileEmail}
          profile={profile}
          setProfile={setProfile}
          setSendLoginPageNumber={setSendLoginPageNumber}
          sendLoginPageNumber={sendLoginPageNumber}
          onClickLoginButton={onClickLoginButton}
          setOnClickLoginButton={setOnClickLoginButton}
        />
        {loading && <Spinner />}
        {!loading && (
          <div className={styles.spinnerDiv}>{radexAppLoginFlow()}</div>
        )}

        <div style={{ display: "flex" }}>
          {!isMobile && !onClickLoginButton && (
            <nav className={styles.navBar}>
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/PZTransfers"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/PZTransfers"
                    >
                      PZ Transfers
                    </NavLink>
                  </li>
                )}
                {/* {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/PZfullfillment"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/PZfullfillment"
                    >
                      PZ Fulfillment
                    </NavLink>
                  </li>
                )} */}
                {/* {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/TXfullfillment"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/TXfullfillment"
                    >
                      TX Fulfillment
                    </NavLink>
                  </li>
                )} */}
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/pzUsers"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/pzUsers"
                    >
                      PZ Users
                    </NavLink>
                  </li>
                )}
                {/* {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/pzST"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/pzST"
                    >
                      PZ ST
                    </NavLink>
                  </li>
                )} */}
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/txOrders"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/txOrders"
                    >
                      TX Orders
                    </NavLink>
                  </li>
                )}
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/txAlpyne"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/txAlpyne"
                    >
                      TX Alpyne
                    </NavLink>
                  </li>
                )}
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/txUsers"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/txUsers"
                    >
                      TX Users
                    </NavLink>
                  </li>
                )}
                {/* {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/txST"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/txST"
                    >
                      TX ST
                    </NavLink>
                  </li>
                )} */}

                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/TXServicecTicket"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/TXServicecTicket"
                    >
                      TX Ticket
                    </NavLink>
                  </li>
                )}
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/PZServicecTicket"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/PZServicecTicket"
                    >
                      PZ Ticket
                    </NavLink>
                  </li>
                )}
                {profileEmail && (
                  <li className={styles.navBarItems}>
                    <NavLink
                      className={`${styles.navLink} ${
                        location.pathname === "/txUSDT"
                          ? styles.activeNavLink
                          : ""
                      }`}
                      to="/txUSDT"
                    >
                      TX USDT
                    </NavLink>
                  </li>
                )}
              </ul>
            </nav>
          )}

          {!onClickLoginButton && profileEmail && (
            <div className={styles.routesContainer}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to="/PZTransfers" replace />}
                />
                <Route path="/PZTransfers" element={<PZTransfers />} />
                <Route path="/PZfullfillment" element={<PZFulfillmentß />} />
                <Route path="/TXfullfillment" element={<TXFullfillment />} />
                <Route path="/pzUsers" element={<PZUsers />} />
                <Route path="/pzST" element={<PZST />} />
                <Route path="/txOrders" element={<TXOrders />} />
                <Route path="/txAlpyne" element={<TxAlpyne />} />
                <Route path="/txUsers" element={<TXUsers />} />
                <Route path="/txST" element={<TXST />} />
                <Route path="/txUSDT" element={<TXUSDT />} />
                <Route
                  path="/TXServicecTicket"
                  element={<TXServiceTicket profileEmail={profileEmail} />}
                />
                <Route
                  path="/PZServicecTicket"
                  element={<PZServiceTicket profileEmail={profileEmail} />}
                />
              </Routes>
            </div>
          )}
        </div>
      </AppContext.Provider>
    </>
  );
}

export default App;
