export const FULFILLMENT_ENUM = {
  accountNum: "Receiver Account No.",
  IFSC: "Receiver IFSC",
  recievedUSDAmount: "USD Amount",
  recievedINRAmount: "INR Amount",
  pollRefresh: "Poll refresh",
  update: "Update",
  email: "Sender Email",
  swap: "Swap",
  areYouSureWantToProceed: "Are you sure want to proceed?",
  confirmButton: "Confirm",
  cancelButton: "Cancel",
  recieverName: "Receiver Acc. Name",
  select: "Select",
  fetchMessages: "Fetch Messages",
  paymentReference: "INR Payment Reference",
  processSelectedMessage: "Process Selected Messages",
  sesionExpired: "Session expired! Please click on the fetch message button!",
  password: "Password",
  selectedChain: "Selected Chain",
  walletAddress: "Wallet Address",
  status: "Status",
  time: "Age",
};
