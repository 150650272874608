import React from "react";
import styles from "./Card.module.scss";
const Card = ({ titleComponent, children }) => {
  return (
    <div className={styles.rudMainDiv}>
      <div className={styles.titleContainer}>{titleComponent}</div>
      <div className={styles.bodyContainer}>{children}</div>
    </div>
  );
};

export default Card;
