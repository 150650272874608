import React, { memo } from "react";
import PropTypes from "prop-types";
import styles from "../RadioButtons/index.module.scss";

const Radio = (props) => {
  const inputProps = {
    type: "radio",
    name: props.name,
    className: props.className,
  };
  return (
    <div>
      <div className={styles.currencyChooseDiv}>
        {props.options.map((RadioItem, i) => (
          <div
            key={i}
            className={
              RadioItem.value === props.value
                ? styles.activeRadioButton
                : styles.radioButton
            }
            // adding className for the working of active state of the radio button
          >
            {/* checked radio button */}
            <input
              checked={RadioItem.value === props.value}
              type="radio"
              {...inputProps}
              id={RadioItem.value}
              value={RadioItem.value}
              className={
                RadioItem.value === props.value
                  ? styles.activeRadioButtonCircle
                  : styles.radioButtonCircle
              }
              onChange={(e) => props.onChangeFunc(e, props)}
            />
            <label className={styles.label} htmlFor={RadioItem.value}>
              {RadioItem.label}
            </label>
          </div>
        ))}
      </div>
      {/* {props.error && (
        <ShakeError
          errorMessage={RUD_ENUM.error_for_radioButtons}
          customStyling={styles.alertSpan}
        />
      )} */}
    </div>
  );
};

Radio.defaultProps = {
  name: "",
  className: "form-check-input",
  outerClassName: "mb-2",
  value: "",
  options: [],
  onChangeFunc: () => {},
  isReq: null,
  onValidateFunc: () => {},
  error: false,
};

Radio.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.array,
  onChangeFunc: PropTypes.func,
  isReq: PropTypes.bool,
  error: PropTypes.bool,
  onValidateFunc: PropTypes.func,
};

export default memo(Radio);
