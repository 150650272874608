export const TX_ALPYNE_ENUM = {
  youNeed: "You Need",
  youPay: "You Pay",
  usdt: "USDT",
  inr: "INR",
  sell: "Sell",
  sellUsdtContent: "Enter amount of USDT to sell/process with Alpyne",
  livePrice: "Live Price",
  lastSellPrice: "Last Sell Price",
  sellUsdtConfirmContent: "Are you sure you want to sell your USDT?",
};
