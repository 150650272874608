export const TX_ORDER_ENUM = {
  date: "Time",
  amount: "Amount",
  email: "Email",
  price: "Price [INR / INRT]",
  destination: "Destination",
  orderID: "Order ID",
  status: "Status",
  paymentType: "Payment Type",
};
