import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import upArrow from "../../assets/upArrow.svg";
import downArrow from "../../assets/downArrow.svg";
import Spinner from "../../components/Spinner/Spinner";
const UsdtSoldHistory = ({ inrLastSellPrice, loading, setLoading }) => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_ALPYNE_INR_PRICE + "/usdt-transaction-history")
      .then((response) => response.json())
      .then((data) => {
        setTransactions(data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        setLoading(false); //  Set loading to false in case of error
      });
  }, []);
  // Function to calculate percentage change
  const calculatePercentageChange = (currentPrice, lastSellPrice) => {
    if (!currentPrice || !lastSellPrice) {
      return 0;
    }
    //percentage change from lastSellPrice to currentPrice
    const percentageChange =
      ((currentPrice - lastSellPrice) / lastSellPrice) * 100;
    return percentageChange.toFixed(2);
  };

  // Function to render arrow based on percentage change
  const renderArrow = (percentageChange) => {
    if (percentageChange > 0) {
      return <img src={upArrow} alt="Up Arrow" className={styles.arrowIcon} />;
    } else if (percentageChange < 0) {
      return (
        <img src={downArrow} alt="Down Arrow" className={styles.arrowIcon} />
      );
    } else {
      return null; // No change, don't render anything
    }
  };

  return (
    <>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        transactions.length > 0 && (
          <div>
            <div className={styles.headerContainer}>Sell History</div>
            <table>
              <thead>
                <tr>
                  <th>Date & Time</th>
                  <th>USDT</th>
                  <th>INR</th>
                  <th>Sell Price</th>
                  <th>Status</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction) => (
                  <tr key={transaction.id}>
                    <td>{transaction.dateTime}</td>
                    <td>{transaction.usdtAmount}</td>
                    <td>{transaction.inrAmount}</td>
                    <td>{transaction.sellPrice}</td>
                    <td>{transaction.status}</td>
                    <td>
                      {calculatePercentageChange(
                        transaction.sellPrice,
                        inrLastSellPrice
                      )}
                      %{" "}
                      {renderArrow(
                        calculatePercentageChange(
                          transaction.sellPrice,
                          inrLastSellPrice
                        )
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      )}
    </>
  );
};

export default UsdtSoldHistory;
