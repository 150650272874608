import React, { useEffect, useState, useMemo } from "react";
import Modal from "react-modal";
import { TX_ORDER_ENUM } from "../../enums/TXOrderEnum";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
import Pagination from "../../components/Pagination/Pagination";
import refreshIcon from "../../assets/refrrshIcon.svg";
import StatusSwitchPopUp from "../StatusSwitchPopUp/StatusSwitchPopUp";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import { collection, getDocs, doc } from "firebase/firestore";
import { radexAuth, tetherxFirestore } from "../../firebase";
import SelectBox from "../../components/SelectBox/SelectBox";
const TXOrders = () => {
  const [currentPage, setCurrentPage] = useState(1); //state to store the current page
  const [pageSizePagination, setPageSizePagination] = useState(10); //state to store the page count in the pagination
  const [userEmails, setUserEmails] = useState([]); //state to store selected user email
  const [selectedUserEmail, setSelectedUserEmail] = useState({
    value: "",
    label: "",
  }); //state to store the seleceted user email from the drop down
  const [accountDetails, setAccountDetails] = useState([]); //  state for storing the account details
  const [modalIsOpen, setModalIsOpen] = useState(false); //state to open and close the modal
  const [TXUserdata, setTXUserData] = useState([]); //state to store he Tetherx user data
  const [purchaseHistoryData, setPurchaseHistoryData] = useState([]); //  state for storing transfer history data

  // Fetch the userData collection from firebase in order to display the emails in the drop down
  useEffect(() => {
    const getTXUserData = async () => {
      try {
        const querySnapshot = await getDocs(
          collection(tetherxFirestore, "userData")
        );
        const result = querySnapshot.docs.map((doc) => doc.data());
        setTXUserData(result);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    getTXUserData();
  }, []);

  // Fetching the user details such as wallet address from the userWalletAddressDetails collection
  useEffect(() => {
    async function fetchUserDetails() {
      if (selectedUserEmail.value) {
        // Fetch the userData collection using profile email as a reference
        const userDataRef = doc(
          tetherxFirestore,
          "userData",
          selectedUserEmail.value
        );
        // Fetch the accountDetails collection using profile email as a reference
        const accountDetailsRef = collection(
          userDataRef,
          "userWalletAddressDetails"
        );
        const querySnapshot = await getDocs(accountDetailsRef);
        const accountDetailsArray = [];
        // Store the account details into accountDetails state
        querySnapshot.forEach((doc) => {
          const accountDetail = doc.data();
          accountDetailsArray.push({
            ...accountDetail,
            recipientAccountID: doc.id,
          });
        });
        // Store the account details in component state
        setAccountDetails(accountDetailsArray);
      }
    }

    fetchUserDetails();
    // Do any other necessary processing based on the URL parameters
  }, [selectedUserEmail]);
  useEffect(() => {
    // Function to extract user emails from PZUserData and update the state
    const extractUserEmails = () => {
      const emails = TXUserdata.map((user) => ({
        value: user.email,
        label: user.email,
      }));
      setUserEmails(emails);
    };

    // Call the extractUserEmails function when PZUserData is updated
    extractUserEmails();
  }, [TXUserdata]);

  // Function that handles the filering of the page based on the given integer
  const filterPage = (e) => {
    const newSize = parseInt(e.target.value);
    if (newSize >= 1) {
      setPageSizePagination(newSize);
      setCurrentPage(1);
    }
  };

  // Trimming the data in the table
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSizePagination;
    const lastPageIndex = firstPageIndex + pageSizePagination;
    return purchaseHistoryData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, purchaseHistoryData, pageSizePagination]);
  useEffect(() => {
    // Fetch the transaction details from back end
    const getTransactionHistoryData = async () => {
      const transferDetails = {
        email: selectedUserEmail.value, // User email
      };
      const idToken = await radexAuth.currentUser.getIdToken(
        /* forceRefresh */ true
      );
      var requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Include the ID token in the headers
          // Add other headers if needed
        },
        body: JSON.stringify(transferDetails),
      };

      try {
        const response = await fetch(
          process.env.REACT_APP_TETHERX_SERVER_URI +
            `/admin-get-user-purchase-history`,
          requestOptions
        );

        if (response.ok) {
          // Request was successful
          const responseData = await response.json();
          // store the trasnfer history data
          setPurchaseHistoryData(responseData.extractedDetails);
        } else {
          // Handle error response
          const errorData = await response.json();
          // Handle the error data
          console.log("errorData, purchase details:", errorData);
        }
      } catch (error) {
        // Handle network or fetch-related errors
        console.error("Error:", error);
      }
    };
    getTransactionHistoryData();
    // Fetch data every 10 minutes
    const interval = setInterval(() => {
      getTransactionHistoryData();
    }, 10 * 60 * 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [selectedUserEmail]);

  // Function that format the epoch time
  function formatEpochTime(epochTime) {
    const date = new Date(epochTime);

    // Define the desired date and time formats
    const dateFormat = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };

    const timeFormat = {
      hour: "2-digit",
      minute: "2-digit",
    };

    // Get the date and time strings in the desired format
    const dateStr = date.toLocaleDateString("en-US", dateFormat);
    const timeStr = date.toLocaleTimeString("en-US", timeFormat);

    // Combine the date and time strings
    const formattedTime = `${dateStr} ${timeStr}`;

    return formattedTime;
  }

  return (
    <>
      <div className={styles.emailDropDown}>
        <SelectBox
          placeHolder="Select an email"
          options={userEmails}
          onChange={(selectedValue) => {
            setSelectedUserEmail(selectedValue);
            // Handle the selected user email if needed
          }}
          setSelectedValue={setSelectedUserEmail}
          selectedValue={selectedUserEmail}
          errorDismissOnclick={() => {}}
        />
      </div>
      {selectedUserEmail.value ? (
        <div>
          <div className={styles.transferOrders}>
            <div className={styles.refreshButtonContainer}>
              {PZ_TRANSFER_ENUM.transferOrders}{" "}
              <ButtonRade
                customStyling={styles.refreshButton}
                // onClick={getData}
              >
                <img
                  src={refreshIcon}
                  alt="refreshIcon"
                  className={styles.refreshIcon}
                />
              </ButtonRade>
            </div>
            <div className={styles.searchContainer}>
              <div className={styles.search}>
                Show
                <input
                  type="number"
                  className={styles.searchPage}
                  onChange={filterPage}
                  max="50"
                />
              </div>

              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={purchaseHistoryData.length}
                pageSize={pageSizePagination}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
          <div class="tableOuterContainer">
            {" "}
            <div className={styles.tableContainer}>
              <table>
                <thead>
                  <tr>
                    <th>{TX_ORDER_ENUM.date}</th>
                    <th>{TX_ORDER_ENUM.amount}</th>

                    <th>{TX_ORDER_ENUM.price} </th>
                    <th>{TX_ORDER_ENUM.paymentType}</th>
                    <th>{TX_ORDER_ENUM.destination}</th>
                    <th>{TX_ORDER_ENUM.orderID}</th>
                    <th>{TX_ORDER_ENUM.status}</th>
                  </tr>
                </thead>
                {currentTableData.length > 0 && (
                  <tbody>
                    {currentTableData.map((post) => (
                      <tr key={post.order_id.S}>
                        <td>
                          <span className={styles.date}>
                            {formatEpochTime(Number(post.created_at))}
                          </span>
                        </td>
                        <td>{post.usdt_amount}</td>
                        <td>{post.inr_amount}</td>
                        <td>UPI</td>
                        <td>
                          {accountDetails.find(
                            (detail) =>
                              detail.recipientAccountID === post.receiver_id
                          )?.walletAddress && (
                            <div className={styles.recipientContainer}>
                              {
                                accountDetails.find(
                                  (detail) =>
                                    detail.recipientAccountID ===
                                    post.receiver_id
                                ).walletAddress
                              }
                            </div>
                          )}
                        </td>

                        {post.order_id.S && (
                          <td className={styles.tooltip}>
                            {post.order_id.S ? (
                              <span title={post.order_id.S}>
                                {post.order_id.S.slice(0, 3)}...
                                {post.order_id.S.slice(-2)}
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                        )}

                        <td>
                          {post.tx_status === "payment_init" ||
                          post.tx_status === "created" ? (
                            <ButtonRade customStyling={styles.statusSwitch}>
                              Processing
                            </ButtonRade>
                          ) : null}
                          {post.tx_status === "payment_received" && (
                            <ButtonRade customStyling={styles.statusSwitch}>
                              Awaiting settlement
                            </ButtonRade>
                          )}
                          {post.tx_status === "payment_rejectd" ||
                            (post.tx_status === "delivery_rejected" && (
                              <ButtonRade
                                customStyling={styles.statusSwitchPending}
                              >
                                Cancelled
                              </ButtonRade>
                            ))}
                          {post.tx_status === "delivery_complete" && (
                            <ButtonRade
                              customStyling={styles.statusSwitchPending}
                            >
                              Completed
                            </ButtonRade>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            overlayClassName={styles.popupOverlay}
            className={styles.popupContent}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
          >
            <StatusSwitchPopUp setModalIsOpen={setModalIsOpen} />
          </Modal>
        </div>
      ) : (
        <div className={styles.selectEmail}></div>
      )}
    </>
  );
};

export default TXOrders;
