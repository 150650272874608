import React, { useState } from "react";
import { PZ_TRANSFER_ENUM } from "../../enums/PZtransferEnum";
import Radio from "../../components/RadioButtons/RadioButton";
import styles from "./index.module.scss";
import ButtonRade from "../../components/RadeButtons";
const StatusSwitchPopUp = ({ setModalIsOpen }) => {
  const [statusSwitchInput, setStatusSwitchInput] = useState(""); //state for storing the status input
  //   Function to handle the status switching of the transfer
  const handleClickStatusSwitch = (e) => {
    setStatusSwitchInput(e.target.value);
  };
  // Function to handlle cancel button
  const handleClickCancelButton = () => {
    setModalIsOpen(false);
  };
  const handleClickUpdateButton = () => {
    setModalIsOpen(false);
  };
  //   Radio button options
  const statusSwitchOptionsTwo = [
    { value: "RefundProcessing", label: "Refund Processing" },
    { value: "RefundComplete", label: "Refund Complete" },
  ];

  return (
    <div>
      <div className={styles.changeStatus}>{PZ_TRANSFER_ENUM.changeStatus}</div>
      <div className={styles.radioButtonContainer}>
        <Radio
          name="statusSwitchOptions"
          value={statusSwitchInput}
          options={statusSwitchOptionsTwo}
          onChangeFunc={handleClickStatusSwitch}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonRade
          customStyling={styles.cancelButton}
          onClick={handleClickCancelButton}
        >
          {PZ_TRANSFER_ENUM.cancelButton}
        </ButtonRade>
        <ButtonRade
          customStyling={styles.updateButton}
          onClick={handleClickUpdateButton}
        >
          {PZ_TRANSFER_ENUM.updateButton}
        </ButtonRade>
      </div>
    </div>
  );
};

export default StatusSwitchPopUp;
