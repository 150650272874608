export const PZ_TRANSFER_ENUM = {
  date: "Time",
  transferID: "Transfer ID",
  recipient: "Recipient",
  receives: "Receives(INR) ",
  accountNumber: "Account Number",
  IFSCCode: "IFSC Code",
  stripeStatus: "Stripe Status",
  statusSwitch: "Refund Status",
  transferOrders: "Transfer Orders",
  changeStatus: "Change Status",
  cancelButton: "Cancel",
  updateButton: "Update",
  email: "Email",
  phoneNumber: "Phone",
};
