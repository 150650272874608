import React from "react";

import styles from "./index.module.scss";
const SendReplyPopUp = ({ setModalIsOpen ,  selectedTicketID,
  handleReplyChange, 
  handleSaveReply, 
}) => {
  
  return (
    <div className={styles.sendReplyPopUpContainer}>
    <textarea
      className={styles.replyMessage}
      type="text"
      placeholder="Type your reply here"
      onChange={(event) => handleReplyChange(selectedTicketID, event)} // Call the handleReplyChange function
    />
    <button
      className={styles.sendButton}
      onClick={() => {
        handleSaveReply(); // Call the handleSaveReply function
        setModalIsOpen(false); // Close the modal
      }}
    >
      Reply
    </button>
  </div>
  );
};

export default SendReplyPopUp;
