import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import { TX_ALPYNE_ENUM } from "../../enums/TxAlpyneEnum";
import ButtonRade from "../../components/RadeButtons";
import UsdtSoldHistory from "../UsdtSoldHistory/UsdtSoldHistory";
import Modal from "react-modal";
import UsdtSellConfirmModal from "../UsdtSellConfirmModal/UsdtSellConfirmModal";

const MAX_DECIMAL_PLACE = 2;
const TxAlpyne = () => {
  //state to set USDT to INR conversion
  const [usdtConvertionRate, setUsdtConvertionRate] = useState([]);
  const [amountInUsdt, setAmountInUsdt] = useState(""); // state for amount in USDT
  const [amountInInr, setAmountInInr] = useState(""); // state for amount in INR
  const [inrLivePrice, setInrLivePrice] = useState(""); // state to set live price
  const [inrLastSellPrice, setInrLastSellPrice] = useState(""); // state to set last sell price
  const [modalIsOpen, setModalIsOpen] = useState(false); //state for opening and closing the modal
  const [errorMessage, setErrorMessage] = useState(""); // state to set error message if there is any
  const [loading, setLoading] = useState(false);
  // handle input field to enter the USDT that user wish to buy
  const handleOnEnterInput = (e) => {
    setErrorMessage("");
    const enteredValue = e.target.value;
    setAmountInUsdt(enteredValue);
    if (usdtConvertionRate) {
      setAmountInInr(enteredValue * usdtConvertionRate);
    }
  };

  // price fetching from alpyne
  useEffect(() => {
    const getUsdtInrRate = async () => {
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await fetch(
          process.env.REACT_APP_ALPYNE_INR_PRICE + "/get-alpyne-inr-price",
          requestOptions
        );
        if (response.ok) {
          // Request was successful
          const { livePrice, lastSellPrice } = await response.json();
          setUsdtConvertionRate(livePrice);
          setInrLivePrice(livePrice);
          setInrLastSellPrice(lastSellPrice);
        }
      } catch (error) {
        console.error("Error fetching USDT to INR rate from alpyne:", error);
      }
    };

    getUsdtInrRate();
  }, []);

  // handle on click sell button
  const handleOnClickSell = () => {
    if (!amountInUsdt || amountInUsdt === 0) {
      setErrorMessage("Please enter a valid amount of USDT to sell!");
    } else {
      setModalIsOpen(true);
    }
  };
  return (
    <>
      <div className={styles.mainBodyContainer}>
        <div className={styles.sellUsdtContent}>
          {TX_ALPYNE_ENUM.sellUsdtContent}
        </div>
        <div className={styles.sellUsdtContainer}>
          <div className={styles.inputBoxContainer}>
            <div className={styles.inputBoxContents}>
              <div className={styles.dollarSymbol}>$</div>
              <input
                value={amountInUsdt}
                onChange={handleOnEnterInput}
                onKeyDown={(e) => {
                  if (e.key === "-" || e.key === "e" || e.key === ".") {
                    e.preventDefault();
                  }
                }}
                type="number"
                placeholder="00.00"
                autoFocus
                autoComplete="off"
                className={styles.inputAmount}
              ></input>
              <div className={styles.usdt}>{TX_ALPYNE_ENUM.usdt}</div>
            </div>
          </div>
          <div className={styles.displayPriceContainer}>
            <div className={styles.inputBoxContents}>
              <div className={styles.inrSymbol}>₹</div>
              <div className={styles.amountInInr}>
                {Number(amountInInr).toFixed(MAX_DECIMAL_PLACE)}
              </div>
            </div>
            <div className={styles.inr}>{TX_ALPYNE_ENUM.inr}</div>
          </div>
          <div className={styles.sellButtonContainer}>
            <ButtonRade
              customStyling={styles.sellButton}
              onClick={handleOnClickSell}
            >
              {TX_ALPYNE_ENUM.sell}
            </ButtonRade>
          </div>
          <div className={styles.livePriceContainer}>
            <div className={styles.livePriceKey}>
              {TX_ALPYNE_ENUM.livePrice}
            </div>
            <div className={styles.livePriceValue}>
              {inrLivePrice
                ? Number(inrLivePrice).toFixed(MAX_DECIMAL_PLACE)
                : "-"}
            </div>
          </div>
          <div className={styles.lastPriceContainer}>
            <div className={styles.lastPriceKey}>
              {TX_ALPYNE_ENUM.lastSellPrice}
            </div>
            <div className={styles.lastPriceValue}>
              {inrLastSellPrice
                ? Number(inrLastSellPrice).toFixed(MAX_DECIMAL_PLACE)
                : "-"}
            </div>
          </div>
        </div>
        <div className={styles.errorMessage}>{errorMessage}</div>
      </div>
      <UsdtSoldHistory
        inrLastSellPrice={inrLastSellPrice}
        setLoading={setLoading}
        loading={loading}
      />
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={styles.popupOverlay}
        className={styles.popupContent}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <UsdtSellConfirmModal
          setModalIsOpen={setModalIsOpen}
          amountInUsdt={amountInUsdt}
          amountInInr={amountInInr}
          inrLivePrice={inrLivePrice}
          inrLastSellPrice={inrLastSellPrice}
          setLoading={setLoading}
        />
      </Modal>
    </>
  );
};

export default TxAlpyne;
